import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Dashboard from './Pages/Dashboard/Index';
import Chart from './Pages/Chart/Index';
import Watchlist from './Pages/Watchlist/Index';
import MobileDashboard from './Pages/MobileDashboard/index';
import MyAccount from './Pages/User/MyAccount';
import Plans from './Pages/Plans';
import DiscordPlans from './Pages/Plans/DiscordPlans';
import Privacy from './Pages/Privacy';
import Summary from './Pages/Summary';
import Home from './Pages/Home';
import Alert from './Pages/Alert';
import NewsletterPopup from './Shared/NewsletterPopup';
import SignupSuccess from './Pages/SignupSuccess';
import Cookies from 'universal-cookie';
import Users from './Admin/Users';
import Analysis from './Pages/Analysis';
import Faq from './Pages/Help/Faq';
import LearningCenter from './Pages/Help/LearningCenter';
import Affiliate from './Pages/Affiliate';
import MostActive from './Pages/MostActive';
import TawkToChatPop from './Shared/TawkToChatPop';
import SharingFilters from './Admin/SharingFilters';
import DiscordFilters from './Admin/DiscordFilters';
import DiscordSettings from './Pages/DiscordSettings';
import * as appConstants from '../Constants/AppConstants';
import DiscordInstructions from './Pages/Help/DiscordInstructions';
import DiscordServers from './Pages/DiscordServers';
import DiscordCallback from './Pages/DiscordServers/DiscordCallback';
import AnalystRatings from './Pages/AnalystRatings';
import PhoneAuth from '@jumbo/components/Common/authComponents/PhoneAuth';
import AgreementPage from './Pages/Dashboard/AgreementPage';
import ImpersonatePage from './Auth/Impersonate';
import * as FirestoreService from '../services/auth/firebase/FirebaseRealTimeDb';
import UpdateProfileOnSignUp from './Pages/UpdateProfileImage';
import SectorHeatmap from './Pages/SectorHeatmap';
import StockTwitFilters from './Admin/StockTwitsUpdates';
import MorningBite from './Pages/MorningBite/index';
import MorningBiteDetail from './Pages/MorningBite/MorningBite';
import EveningBite from './Pages/EveningBite/index';
import EveningBiteDetail from './Pages/EveningBite/EveningBite';
import { analytics } from '../services/configs/config';
import DiscordLoginCallback from '../services/auth/DiscordLoginCallback';
import ManualNotifications from './Admin/ManualNotifications';
import TradeIdeas from './Pages/TradeIdeas';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { logEvent } from 'firebase/analytics';
import Feeds from './Pages/Feeds/Feeds';
import Wall from './Pages/Feeds/Wall';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);


const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetails } = authUser || {};
  
  if (authUser && authUser.phoneNumber === null) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/verify-phone',
            }}
          />
        )}
      />
    );
  } else if (userDetails && userDetails.hasOwnProperty('agreement') && userDetails.agreement.isAgreementDone === false) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/agreement',
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

// For agreement popup
const RestrictedRouteAgreement = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetails } = authUser || {};

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Fragment>
            {userDetails && userDetails.hasOwnProperty('agreement') && userDetails.agreement.isAgreementDone === false ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: props.location },
                }}
              />
            )}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

// For phone auth
const RestrictedRoutePhone = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Fragment>
            {authUser && authUser.phoneNumber === null ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: props.location },
                }}
              />
            )}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RestrictedSubscriptionRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetails } = authUser || {};
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);

  if (authUser && authUser.phoneNumber === null) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/verify-phone',
            }}
          />
        )}
      />
    );
  } else if (userDetails && userDetails.hasOwnProperty('agreement') && userDetails.agreement.isAgreementDone === false) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/agreement',
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser &&
        userSubscription !== null &&
        userSubscription.id &&
        userSubscription.role === appConstants.Role_Customer ? (
          <Component {...props} />
        ) : authUser && userSubscription !== null && userSubscription.id ? (
          <Redirect
            to={{
              pathname: '/discord-servers',
            }}
          />
        ) : authUser ? (
          <Redirect
            to={{
              pathname: '/plans',
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RestrictedDiscordSubscriptionRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetails } = authUser || {};
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);

  if (authUser && authUser.phoneNumber === null) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/verify-phone',
            }}
          />
        )}
      />
    );
  } else if (userDetails && userDetails.hasOwnProperty('agreement') && userDetails.agreement.isAgreementDone === false) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/agreement',
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser &&
        userSubscription !== null &&
        userSubscription.id &&
        userSubscription.role === appConstants.Role_Discord ? (
          <Component {...props} />
        ) : authUser && userSubscription !== null && userSubscription.id ? (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        ) : authUser ? (
          <Redirect
            to={{
              pathname: '/plans',
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RestrictedAdminRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        authUser && authUser.isAdmin === true ? (
          <Component {...props} />
        ) : authUser ? (
          <Redirect
            to={{
              pathname: '/my-account',
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const location = useLocation();
  const cookies = new Cookies();

  useEffect(() => {
    FirestoreService.updateLastActivityDateTime();
    
    // analytics.setCurrentScreen(window.location.pathname);
    logEvent(analytics, 'page_view');
  }, [authUser, location.pathname]);

  if (authUser) {
    if (location.pathname === '/signin') {
      return <Redirect to={'/dashboard'} />;
    } else if (location.pathname === '/signup') {
      if (cookies.get('fromSignup') === 'true') {
        cookies.set('fromSignup', 'false', {
          path: '/',
          expires: new Date(new Date().setMinutes(new Date().getMinutes() + 5)),
        });

        analytics.logEvent('Signup_Completed');

        if (userSubscription !== null && userSubscription.id && userSubscription.role === appConstants.Role_Discord) {
          return <Redirect to={'/discord-instructions'} />;
        }

        return <Redirect to={'/dashboard'} />;
        //return <Redirect to={'/signup-success'} />;
      } else {
        return <Redirect to={'/dashboard'} />;
      }
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedAdminRoute path="/admin/sharing-filters" component={SharingFilters} />
        <RestrictedAdminRoute path="/admin/manual-notifications" component={ManualNotifications} />
        <RestrictedAdminRoute path="/admin/stocktwit-filters" component={StockTwitFilters} />
        <RestrictedAdminRoute path="/admin/discord-filters" component={DiscordFilters} />
        <RestrictedAdminRoute path="/admin/users" component={Users} />
        <Route path="/plans" component={Plans} />
        <Route path="/discord-plans" component={DiscordPlans} />
        <Route path="/signin" component={Login} />
        <Route path="/impersonate" component={ImpersonatePage} />
        {/* <Route path="/signup" component={Register} /> */}
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/faq" component={Faq} />
        <Route path="/signup-update" component={UpdateProfileOnSignUp} />
        <Route path="/callback" component={DiscordLoginCallback} />
        <RestrictedRoutePhone path="/verify-phone" component={PhoneAuth} />
        <RestrictedRouteAgreement path="/agreement" component={AgreementPage} />
        <RestrictedRoute path="/affiliate" component={Affiliate} />
        <RestrictedRoute path="/my-account" component={MyAccount} />
        <RestrictedRoute path="/signup-success" component={SignupSuccess} />
        <RestrictedDiscordSubscriptionRoute path="/discord-callback" component={DiscordCallback} />
        <RestrictedDiscordSubscriptionRoute path="/discord-servers" component={DiscordServers} />
        <RestrictedDiscordSubscriptionRoute path="/discord-settings" component={DiscordSettings} />
        <RestrictedDiscordSubscriptionRoute path="/discord-instructions" component={DiscordInstructions} />
        <RestrictedSubscriptionRoute path="/analyst-ratings" component={AnalystRatings} />
        <RestrictedSubscriptionRoute path="/most-active" component={MostActive} />
        <RestrictedSubscriptionRoute path="/sector-heatmap" component={SectorHeatmap} />
        <RestrictedSubscriptionRoute path="/morning-bite" component={MorningBite} />
        <RestrictedSubscriptionRoute path="/morning-bite-view" component={MorningBiteDetail} />
        <RestrictedSubscriptionRoute path="/evening-bite" component={EveningBite} />
        <RestrictedSubscriptionRoute path="/evening-bite-view" component={EveningBiteDetail} />
        <RestrictedRoute path="/learning-center" component={LearningCenter} />
        <RestrictedSubscriptionRoute path="/alert" component={Alert} />
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute path="/posts" component={Wall} />
        <RestrictedRoute path="/chart" component={Chart} />
        <RestrictedRoute path="/watchlist" component={Watchlist} />
        <RestrictedRoute path="/mobile-dashboard" component={MobileDashboard} />
        <RestrictedRoute path="/summary" component={Summary} />
        <RestrictedRoute path="/analysis" component={Analysis} />
        <RestrictedRoute path="/trade-ideas" component={TradeIdeas} />
        <Route exact path="/" component={Login} />
        <Route component={Error404} />
      </Switch>

      <TawkToChatPop />
      <NewsletterPopup />
    </React.Fragment>
  );
};

export default Routes;
